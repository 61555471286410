import { Col, PaginationProps, Row } from "antd";
import { ColumnProps } from "antd/lib/table";
import { CourseSection, CourseSectionFilter } from "models/CourseSection";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  InputText,
  Modal,
  OneLineText,
  Pagination,
  Select,
  StandardTable,
} from "react3l-ui-library";
import nameof from "ts-nameof.macro";
import { Student, StudentFilter } from "models/Student";
import { TableRowSelection } from "antd/lib/table/interface";
import { renderMasterIndex } from "helpers/table";
import { Gender } from "models/Gender";
import LayoutHeader from "components/LayoutHeader";
import LayoutCell from "components/LayoutCell";
import { formatNumber } from "helpers/number";
import { tableService } from "services/page-services/table-service";
import TruncateCell from "components/TruncateCell";
import { courseSectionRepository } from "repositories/course-section-repository";
import { GuidFilter, IdFilter } from "react3l-advanced-filters";
import React from "react";
import { Observable } from "rxjs";
import { Cohort, CohortFilter } from "models/Cohort";

interface CourseSectionStudentModalProps {
  visible: boolean;
  listStudent?: Student[];
  countStudent?: number;
  loadList?: boolean;
  studentFilter?: StudentFilter;
  rowSelection?: TableRowSelection<any>;
  handleClose: () => void;
  handleSave: (data: CourseSection) => void;
  handleChangeInputFilter?: (value?: any) => void;
  handleChangeGeneralClassFilter: () => (
    idValue: number,
    value: CourseSection
  ) => void;
  handleChangeCohortFilter: () => (idValue: number, value: Cohort) => void;
  handleTableChange?: (value: any) => void;
  handlePagination?: (skip: number, take: number) => void;
  handleChangerRowSelection: (record: any) => void;
}
export function CourseSectionStudentModal(
  props: CourseSectionStudentModalProps
) {
  const {
    rowSelection,
    visible,
    studentFilter,
    listStudent,
    countStudent,
    loadList,
    handleSave,
    handleClose,
    handleChangeInputFilter,
    handleChangeGeneralClassFilter,
    handleChangeCohortFilter,
    handleTableChange,
    handlePagination,
    handleChangerRowSelection,
  } = props;

  const [translate] = useTranslation();

  const pagination: PaginationProps = tableService.usePagination(
    studentFilter,
    countStudent
  );

  const filterListGeneralClass = React.useCallback(
    (filter?: CourseSectionFilter) => {
      return new Observable<any[]>((observer) => {
        courseSectionRepository
          .filterListGeneralClass(filter)
          .subscribe((res) => {
            if (res && res?.length > 0) {
              res.forEach((item, index) => (item.id = index));
              observer.next(res);
            } else observer.next([]);
          });
      });
    },
    []
  );
  

  const columns: ColumnProps<Student>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
            position="center"
          />
        ),
        key: "index",
        width: 60,
        render(...params: [number, Student, number]) {
          const index = renderMasterIndex<Student>(pagination)(...params);
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={formatNumber(index)} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate(
              "courseSections.courseSectionStudentMappings.code"
            )}
          />
        ),
        key: "code",
        dataIndex: "code",
        width: "20%",
        render(...params: [string, Student, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate(
              "courseSections.courseSectionStudentMappings.name"
            )}
          />
        ),
        key: "name",
        dataIndex: "name",
        render(...params: [string, Student, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate(
              "courseSections.courseSectionStudentMappings.className"
            )}
          />
        ),
        key: "className",
        dataIndex: "className",
        width: "20%",
        render(...params: [string, Student, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate(
              "courseSections.courseSectionStudentMappings.gender"
            )}
          />
        ),
        key: "gender",
        dataIndex: "gender",
        width: 120,
        render(...params: [Gender, Student, number]) {
          return <TruncateCell renderContent={params[0]?.name} />;
        },
      },
    ],
    [pagination, translate]
  );

  return (
    <Modal
      size={1024}
      visibleFooter={true}
      visible={visible}
      title={translate(
        "courseSections.courseSectionStudentMappings.modalTitle"
      )}
      handleSave={handleSave}
      handleCancel={handleClose}
      titleButtonCancel={translate("general.actions.close")}
      titleButtonApply={translate("general.actions.save")}
    >
      <div className="modal-content">
        <Row>
          <Col lg={12} className="m-b--xxs p-r--sm">
            <InputText
              value={studentFilter?.search}
              placeHolder={translate(
                "courseSections.courseSectionStudentMappings.placeholder.search"
              )}
              onChange={handleChangeInputFilter}
              type={0}
            />
          </Col>
          <Col lg={6} className="m-b--xxs p-r--sm">
            <Select
              type={0}
              classFilter={CourseSectionFilter}
              placeHolder={translate(
                "courseSections.courseSectionStudentMappings.placeholder.generalClass"
              )}
              getList={filterListGeneralClass}
              onChange={handleChangeGeneralClassFilter()}
              value={studentFilter?.generalClass}
            />
          </Col>
          <Col lg={6} className="m-b--xxs p-r--sm">
            <Select
              type={0}
              classFilter={CohortFilter}
              placeHolder={translate(
                "courseSections.courseSectionStudentMappings.placeholder.cohort"
              )}
              getList={courseSectionRepository.filterListCohort}
              onChange={handleChangeCohortFilter()}
              value={studentFilter?.cohort}
            />
          </Col>
        </Row>
        <div className=" m-t--sm">
          <StandardTable
            rowKey={nameof(listStudent[0].id)}
            columns={columns}
            dataSource={listStudent}
            isDragable={true}
            scroll={{ y: 500 }}
            tableSize={"md"}
            onChange={handleTableChange}
            loading={loadList}
            rowSelection={rowSelection}
            bordered
            onRow={(record) => {
              return {
                onClick: (_event) => handleChangerRowSelection(record), // click row
              };
            }}
          />
          <Pagination
            skip={studentFilter?.skip}
            take={studentFilter?.take}
            total={countStudent}
            onChange={handlePagination}
          />
        </div>
      </div>
    </Modal>
  );
}
